import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { filter } from 'lodash';

// import Iconify from '../components/iconify/Iconify';
import { ReviewsList, ReviewsPdf } from '../sections/@dashboard/reviews';
import { useGetAnEmployeePdfQuery, useGetAllEmployeeQuery,useGetPreviousReviewsEmployeesQuery } from '../features/review/reviewApi';

import { UserListToolbar } from '../sections/@dashboard/user';


// ----------------------------------------------------------------------



export default function PreviousReviewPage() {
    // const theme = useTheme();
    const { data: employeeRatingList,refetch  } = useGetPreviousReviewsEmployeesQuery();
    const [id, setId] = useState({ id: 0 });
    const { data: employeeRatingPdfList} = useGetAnEmployeePdfQuery(id.employeeId);

    console.log("employeeRatingPdfList- Previous Review->",employeeRatingPdfList)

    const [filterName, setFilterName] = useState('');

    const handleFilterByName = (event) => {

        setFilterName(event.target.value);
    };

    useEffect(() => {
        refetch(); 
    }, [refetch]);

    return (
        <>
            <Helmet>
                <title> Dashboard | PEL Group </title>
            </Helmet>

            <Container maxWidth="xl">

                <Typography variant="h4" >
                    Previous Reviews
                </Typography>
                <Typography variant="body1" sx={{ mb: 5 }}>
                    Here's your previous completed reviews
                </Typography>


                <Grid container spacing={3} >

                    <Grid item xs={12} sm={6} borderRight={"1px solid #888"}>
                        <UserListToolbar labelname="Search User.." filterName={filterName} onFilterName={handleFilterByName} />

                        {filterName === '' ?
                            <ReviewsList setId={setId} id={id.employeeId} list={employeeRatingList?.data.filter((item) => item?.color === 'green')} />
                            : <ReviewsList setId={setId} id={id.employeeId} list={employeeRatingList?.data.filter((item) => item?.color === 'green' && item?.employeeName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1)} />}

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {id && <ReviewsPdf id={id} list={employeeRatingPdfList?.data} />}
                    </Grid>


                </Grid>
            </Container>
        </>
    );
}
