import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Grid, Container, Typography, Box, Card, CardHeader, CardActions, TextField } from '@mui/material';
import PropTypes from 'prop-types'
import { Form, Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Button from '@mui/material/Button';


import { useAddCompanyMutation, useGetCompanyQuery, useGetIDCompanyQuery, useEndDateExpiredMutation } from '../features/company/companyApi';




// sections
import {
    AppTasks,
    AppWidgetSummary,
} from '../sections/@dashboard/app';
// import DateField from '../components/date-picker/DatePicker';
import { useGetEmployeeTaskQuery, useGetAnTaskBYDesUserQuery, useGetTaskQuery } from '../features/tasks/tasksApi';


// ----------------------------------------------------------------------

function DashboardPage({ options }) {
    const [addCompany] = useAddCompanyMutation();
    const [endDateExpired] = useEndDateExpiredMutation();

    const { user } = useSelector((state) => state.auth);
    const { data } = useGetTaskQuery();

    const [startDate, setstartDate] = useState(dayjs(new Date()));
    const [checkstartDate, checksetstartDate] = useState(dayjs(new Date()));

    const [endDate, setendtDate] = useState(null);
    const [year, setYear] = useState(null);
    const [yearOptions, setYearOptions] = useState(null);
    if (localStorage.getItem('CompanyID') === null || localStorage.getItem('CompanyID') === undefined) {
        localStorage.setItem("CompanyID", 1);
    }

    const [company, setcompanyvalue] = useState(parseInt(localStorage.getItem('CompanyID'), 10));

    const [isEndDateExpired, setIsEndDateExpired] = useState(false);
    const [issetValue, setissetValue] = useState(false);
    const [binvalue, setbinvalue] = useState({
        endValue: null,
        companyId: 0
    }
    );

    const bindCompany = (e) => {
        
        let sDate = null;
        const SYDate = dayjs(new Date(startDate.$y + 1, startDate.$M, startDate.$D+1));
        const eDate = dayjs(new Date(e.$y, e.$M, e.$D+1));
        if(checkstartDate.$D === startDate.$D){
            sDate = new Date();
        }
        else{
            sDate = dayjs(new Date(startDate.$y, startDate.$M, startDate.$D+1));
        }
        if (eDate.toISOString().split('T')[0] > SYDate.toISOString().split('T')[0] || 
            eDate.toISOString().split('T')[0] <= sDate.toISOString().split('T')[0]) {
            setissetValue(true);
            alert("Please select valid date range");
            
        }     
        else {
            setendtDate(e);
            let id = 0;
            if (binvalue.companyId !== 0) {
                id = binvalue.companyId;
            }
            if (startDate != null) {
                let updatevalue = {};

                updatevalue = {
                    companyId: id, startDate: new Date(startDate.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                    endDate: new Date(e.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                    year
                };

                setbinvalue(() => ({
                    endValue: updatevalue?.endDate,
                    companyId: updatevalue?.companyId
                }));
                onSubmit(updatevalue);

            }


        }
    };

     const addYear = (e)=>{
            setYear(e.target.value)
            let id = 0;
            if (binvalue.companyId !== 0) {
                id = binvalue.companyId;
            }
            if (startDate != null) {
                let updatevalue = {};
                updatevalue = {
                    companyId: id,
                    startDate: new Date(startDate.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                    endDate: new Date(endDate.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                    year:e.target.value.toString()
                };
                onSubmit(updatevalue);
            }   
     }


    const handleChange = (event) => {
        setcompanyvalue(event.target.value);
        localStorage.setItem('CompanyID', event.target.value);
        setbinvalue(() => ({
            endValue: null,
            companyId: 0
        }));

        setstartDate(dayjs(new Date()));
        setendtDate(dayjs(null));

    };
    const { data: companydata } = useGetIDCompanyQuery(company);


    useEffect(
        () => {
            if (companydata && (binvalue.endValue == null)) {

                if (companydata.data.companyId === company &&
                    (companydata?.data?.startDate !== undefined)) {
                    setstartDate(dayjs(companydata?.data?.startDate));

                }

                if (companydata.data.companyId === company && companydata?.data?.endDate !== undefined) {
                    setendtDate(dayjs(companydata?.data?.endDate));
                    
                    const formatedEndDate = dayjs(companydata?.data?.endDate).format('YYYY-MM-DD');
                    const formatedCurrentDate = dayjs().format('YYYY-MM-DD');

                    setIsEndDateExpired(dayjs(formatedEndDate).isBefore(dayjs(formatedCurrentDate)));
                }

                if (companydata.data.companyId === company && companydata?.data?.year !== undefined) {
                    setYear(companydata?.data?.year);  
                }

                if (startDate === undefined) {
                    setstartDate(null);

                }
                if (startDate === undefined) {
                    setendtDate(null);

                }

                if (year === undefined) {
                    setYear(null);
                }

                if (companydata.data.companyId === company) {
                    setbinvalue(() => ({
                        endValue: companydata?.data?.endDate,
                        companyId: companydata?.data?.companyId
                    }));
                }
                const startYear = dayjs( companydata?.data?.startDate).year();
                const endYear = startYear + 1;
                const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
                setYearOptions(years);


            }
            if(issetValue === true){
                setendtDate(null);
                setissetValue(false);
                window.location.reload();
            }
        }, [company, companydata,year, issetValue,startDate, binvalue]);



    const onSubmit = (values) => {        
        addCompany({
            ...values
        });

        setTimeout(() => {
            window.location.reload();
        }, 1000);

    };

    const onEndDateExpired = (isEndDateExpired) => {
        let id = 0;
        if (binvalue.companyId !== 0) {
            id = binvalue.companyId;
        }

        let updatevalue = {};
        if (startDate != null) {
            updatevalue = {
                companyId: id, 
                startDate: new Date(startDate.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                endDate: new Date(endDate.toDate().getTime() + 500 * 60000).toISOString().split('T')[0],
                year
            };

            setbinvalue(() => ({
                companyId: updatevalue?.companyId
            }));
        }

        updatevalue.isEndDateExpired = isEndDateExpired;
        
        endDateExpired({
            ...updatevalue
        });

    };

    return (


        <>
            <Helmet>
                <title> Dashboard | PEL Group </title>
            </Helmet>
            {
                user?.userType === 'A' ? (
                    <><Container maxWidth="xl">

                        <Box sx={{ maxWidth: 'sm' }}>
                            <FormControl fullWidth>                         
                                <InputLabel id="demo-simple-select-label" sx={{ fontSize: '3' }} >Company</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={company}
                                    label="Company"
                                    onChange={handleChange}>
                                    {
                                        options?.map(option => (

                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label ?? option.value}
                                            </MenuItem>

                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ mt: 3 }}>

                            <Typography variant="h5" sx={{ mb: 5 }}>
                                {`Hello ${user?.firstName} ${user?.lastName}`}
                            </Typography>

                            {
                                binvalue.endValue ?
                                    <Typography variant="h5" sx={{ mb: 5 }}>

                                        Current End Date : {`${binvalue.endValue.split('-')[2]}/${binvalue.endValue.split('-')[1]}/${binvalue.endValue.split('-')[0]}`}
                                    </Typography> :
                                    <Typography variant="h5" sx={{ mb: 5 }}>

                                        Current End Date
                                    </Typography>
                            }

                        </Box>


                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="h5" color={"black"} sx={{ mb: 2 }}>
                                    Set New Reviews Date
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker

                                            defaultValue={dayjs(startDate)}
                                            value={dayjs(startDate)}
                                            format="DD/MM/YYYY"
                                            minDate={dayjs()}
                                            maxDate={dayjs().add(1, 'year')}
                                            onChange={(newValue) => setstartDate(newValue)}
                                            disablePast={false}

                                        />
                                    </DemoContainer>

                                </LocalizationProvider>
                               
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>

                                <Typography variant="h5" color={"black"} sx={{ mb: 2 }}>
                                    Set New End Date
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>

                                        <DatePicker

                                            defaultValue={dayjs(endDate)}
                                            value={dayjs(endDate)}
                                            format="DD/MM/YYYY"
                                            minDate={startDate} 
                                            maxDate={startDate && startDate.add(1, 'year')}
                                            onChange={(e) => bindCompany(e)}
                                        />


                                    </DemoContainer>

                                </LocalizationProvider>



                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>

                                <Typography variant="h5" color={"black"} sx={{ mb: 2 }}>
                                    Year of Review
                                </Typography>

                                <Select
                                    value={year}
                                    defaultValue={year}
                                    sx={{width: '200px', marginTop: '5px' }}
                                    onChange={addYear}
                                    >
                                    {
                                        yearOptions?.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option ?? option}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>

                            </Grid>

                            <Grid item xs={12} sm={6} md={1}>
                                {/* <LoadingButton type="submit" variant="contained" name="Update" sx={{ float: 'right', mr: 5, mt:7 }} onClick={() => onEndDateExpired(isEndDateExpired)}>
                                  { isEndDateExpired ? 'Go' : 'Update'}
                                </LoadingButton> */}
                                <LoadingButton type="submit" variant="contained" name="Update" sx={{ float: 'right', mr: 5, mt:7 }} onClick={() => onEndDateExpired(true)}>
                                  Go
                                </LoadingButton>
                              
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                {/* <LoadingButton type="submit" variant="contained" name="Update" sx={{ float: 'right', mr: 5, mt:7 }} onClick={() => onEndDateExpired(isEndDateExpired)}>
                                  { isEndDateExpired ? 'Go' : 'Update'}
                                </LoadingButton> */}
                                
                                <LoadingButton type="submit" variant="contained" name="Update" sx={{ float: 'right', mr: 5, mt:7 }} onClick={() => onEndDateExpired(false)}>
                                  Update
                                </LoadingButton>
                            </Grid>



                            <Grid item xs={13} md={10} >
                                {user?.userType === 'A' || user?.userType === 'M' ? <AppTasks
                                    title={`${data?.data.filter(x => x.status === 'Request To Open').length || 0} Active Tasks`}
                                    list={data?.data.filter(x => x.status === 'Request To Open')} user={user}
                                /> : <></>}
                                {/* {user?.userType === 'E' && <EmployeeTasks />} */}
                            </Grid>

                            {/* <Grid item xs={13} md={6} >
                                {taskUser?.status === 'Request To Open' ? <>
                                    <UpdateTask item={taskUser} user={user} /></> : <></>
                                }



                            </Grid> */}
                        </Grid>


                    </Container></>
                ) : (
                    <><Container maxWidth="xl">
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                            <Typography variant="h5" sx={{ mb: 5 }}>
                                {`Hello ${user?.firstName} ${user?.lastName}`}
                            </Typography>
                            


                        </Box>
                        {/* <div className="watermark">{options?.filter(x=>x.location === user?.location)[0]?.label }</div> */}
                        <div className="watermark"><img style={{width:"60%"}} alt="UserHome" src="/assets/UserHome.jpg" /></div>
                    </Container></>
                )
            }


        </>
    );
}


const EmployeeTasks = () => {
    const { user } = useSelector(state => state.auth)
    const { data } = useGetEmployeeTaskQuery(user.id);

    return (<AppTasks
        title={`${data?.data?.length || 0} Active Tasks`}
        list={data?.data}
    />
    )
}

//----------------------------------

DashboardPage.propTypes = {

    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        location: PropTypes.string,
    })),

}
export default function AddCompanyPage() {
    const { data } = useGetCompanyQuery();

    const options = data?.data?.map((item) => ({
        label: item.companyName,
        value: item?.companyId,
        location: item?.location,
    }));

    console.log(options)

    return (
        <>
            <DashboardPage options={options} />
        </>
    )
}


// function UpdateTask({ item, user }) {
//     const [UpdateTask, { data, isLoading, isSuccess }] = useUpdateTaskMutation();
//     const initialValues = {
//         taskName: '',
//         status: ''
//     }

//     const validationSchema = Yup.object({
//         taskName: Yup.string().required("Task Name is required"),
//     })

//     const [taskForm, setTaskForm] = useState({
//         taskId: 0,
//         taskName: null,
//         status: null
//     });
//     const [updateSatus, setUpdate] = useState(null);

//     const onDenay = (event) => {
//         if (event !== null && (event.target.name === 'Deny' ||event.target.name === 'Complete'))
//             UpdateTask({
//                 ...taskForm,
//                 taskId: item?.taskId,
//                 status: event.target.name
//             })
//     }
//     const onSubmit = (values) => {
//         UpdateTask({
//             ...values,
//             taskId: item?.taskId,
//             status: 'Complete'
//         })
//     }



//     return (

//         <Formik
//             initialValues={initialValues}
//             validationSchema={validationSchema}
//             onSubmit={onSubmit}>
//             <Form>
//                 <Card sx={{ width: 350 }}>
//                     <CardHeader sx={{ textAlign: 'center' }} title="Request is open for" subheader={user?.firstName} />
//                     <Grid item xs={7} sx={{ ml: 8 }} >
//                         <Field name="taskName">
//                             {({ field }) => (
//                                 <TextField {...field} required fullWidth label="Task Name" value={taskForm.taskName} onChange={(e) => setTaskForm({ ...taskForm, taskName: e.target.value })} />
//                             )}
//                         </Field>
//                         {/* <ErrorMessage name="taskName">
//                             {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
//                         </ErrorMessage> */}
//                     </Grid>
//                     <CardActions>

//                         <Grid item xs={3}  >

//                             <Button variant="contained" name="Deny" sx={{ml:6}} onClick={(event) => onDenay(event)}>
//                                 Deny
//                             </Button>
//                         </Grid>
//                         <Grid item xs={10}  >
//                             <LoadingButton type="submit" variant="contained" name="Complete" sx={{ float: 'right', mr:5 }} onClick={(event) => onDenay(event)}>
//                                 Approve
//                             </LoadingButton>




//                         </Grid>


//                     </CardActions>

//                 </Card>
//             </Form></Formik>


//     )

// }